import { ISectionOrigin } from '@/shared/models/Section';
import { WebsitePageType } from '@/shared/models/Website';
import { getVideoUrl } from '@/shared/utils/helpers';
import { getEventSchema, getGameSchema } from '@/shared/utils/seo';
import { getClubFallbackImages } from '@/shared/utils/getClubUrl';
import TitleMeta from 'components/Meta/TitleMeta';
import { usePage } from 'contexts/page';
import { useClub } from 'contexts/club';
import { useTranslations } from 'contexts/translations';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import getGameTitle from 'services/games';
import { ISectionComponentProps } from '../Sections/utils';
import DescriptionMeta from './DescriptionMeta';
import ImageMeta from './ImageMeta';
import SchemaMeta from './SchemaMeta';
import VideoMeta from './VideoMeta';

// per page custom title
export default function CustomTitleMeta(props: ISectionComponentProps) {
    const { section } = props
    const { data } = props
    const { page } = usePage()
    const { club } = useClub()
    const { translations } = useTranslations()
    const clubFallbacks = getClubFallbackImages(club).slice(-1)
    const intl = useIntl()
    const fragments = []

    // default title for predefined page and system page
    if([ISectionOrigin.PREDEFINED, ISectionOrigin.SYSTEM].includes(section.origin)) {
        const { group, league } = data
        const title = [
            group && group.name,
            league && league.name,
        ].filter(Boolean).join(' - ')
        fragments.push(
            <TitleMeta key='default-title' title={title} />
        )
    }


    // single video
    if(page.type === WebsitePageType.VIDEO) {
        const  { video } = data
        fragments.push(
            <TitleMeta key='video-title' pageTitleSuffix={false} title={video?.title} />,
            <DescriptionMeta key='video-description' description={video?.description}/>,
            <VideoMeta key='video-meta' video={getVideoUrl(video)}/>,
            <ImageMeta key='video-image' image={video?.image_big || clubFallbacks[0].src} />
        )
    }

    // single news
    if(page.type === WebsitePageType.NEWS && data?.singleNews) {
        const { singleNews } = data
        fragments.push(
            <TitleMeta key='news-title' pageTitleSuffix={false} title={singleNews?.title} />,
            <DescriptionMeta key='news-description' description={singleNews?.description_no_html}/>,
            <ImageMeta key='news-image' image={singleNews?.image_big || clubFallbacks[0].src} />,
            <Head key='news-type'>
                <meta property="og:type" content="article" key="ogtype"/>
            </Head>,
        )
    }

    // single post
    if(page.type === WebsitePageType.POST && data?.post) {
        const { post } = data
        const paragraph = post?.contents?.find(c => c.layout === 'paragraph')
        const imagesContent = post?.contents?.find(c => c.layout === 'images')
        const image = imagesContent?.layout === 'images' &&
            imagesContent.content?.images?.length ?
                imagesContent.content?.images[0]?.url : ''
        const description = paragraph?.layout === 'paragraph' ? paragraph.content?.paragraph : ''

        fragments.push(
            <TitleMeta key='post-title' pageTitleSuffix={false} title={post?.title} />,
            <DescriptionMeta key='post-description' description={description}/>,
            <ImageMeta key='news-image' image={image || clubFallbacks[0].src} />,
            <Head key='news-type'>
                <meta property="og:type" content="article" key="ogtype"/>
            </Head>,
        )
    }

    // single gallery
    if(page.type === WebsitePageType.GALLERY) {
        const { gallery } = data
        fragments.push(
            <TitleMeta key='gallery-title' pageTitleSuffix={false} title={data.gallery?.title} />,
            <ImageMeta key='gallery-image' image={gallery?.image_big || clubFallbacks[0].src} />,
            <DescriptionMeta key='gallery-description' description={gallery?.description}/>,
        )
    }

    // player profile
    if(page.type === WebsitePageType.PLAYER) {
        const  { player } = data
        fragments.push(
            <TitleMeta key='player-title' pageTitleSuffix={false} title={[player?.firstname, player?.lastname].filter(Boolean).join(' ')} />,
            <ImageMeta key='player-image' image={player?.picture} />
        )
    }


    // game page
    if(page.type === WebsitePageType.GAME) {
        const { game, categories, placeholders, event } = data
        if (game && categories && placeholders && event) {
            const title = getGameTitle(game, categories, placeholders[translations.locale], intl)
            fragments.push(
                <TitleMeta key='game-title' pageTitleSuffix={false} title={title} />,
                <DescriptionMeta key='game-description' description={event?.information}/>,
                <ImageMeta key='game-image' image={event.picture || clubFallbacks[0].src} />,
                <SchemaMeta key='game-schema' schema={getGameSchema(game, event)}/>
            )
        }
    }

    // event, training page
    if([WebsitePageType.EVENT, WebsitePageType.TRAINING].includes(page.type)) {
        const { event } = data
        if (event) {
            fragments.push(
                <TitleMeta key='event-title' pageTitleSuffix={false} title={event?.title} />,
                <DescriptionMeta key='event-description' description={event?.information}/>,
                <ImageMeta key='event-image' image={event.picture || clubFallbacks[0].src} />,
                <SchemaMeta key='event-schema' schema={getEventSchema(event)}/>
            )
        }
    }

    return (
        <>
            {fragments}
        </>
    )
}